import Papa from 'papaparse'

export const newObjectID = () => {
	let timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
	return (
		timestamp +
		"xxxxxxxxxxxxxxxx"
			.replace(/[x]/g, function () {
				return ((Math.random() * 16) | 0).toString(16);
			})
			.toLowerCase()
	);
};

const isValidId = (id) => typeof id === 'string' && /^[0-9a-fA-F]{24}$/.test(id);

export const filterData = (data) => {
    let newData = data;

    newData.nodes = Object.values(newData.nodes ?? []).reduce((acc, node) => {
        if (!isValidId(node.id)) {
            console.error(`Invalid Node ID: ${node}`);
            return acc;
        }

        const contextActions = node?.contextActions?.map((item) => ({
				type: item.type ?? "",
				value: item.value ?? "",
				key: item.key ?? "",
			})) ?? [];

		return {
			...acc,
			[node.id]: { ...node, contextActions },
		};
	}, {});

	newData.responses = Object.values(newData.responses ?? [])
		.filter(
			(response) =>
				{
            if (!isValidId(response.id)) {
                console.error(`Invalid Response ID: ${response}`);
                return false;
            }
            return Object.values(newData.nodes ?? []).findIndex((node) =>
					(node.responses ?? []).includes(response.id)
				) !== -1
		;
		})
        .reduce((acc, response) => ({
            ...acc,
            [response.id]: response,
			}),

    {}
		);
	newData.edges = (() => {
		let elems = [];
		for (let elem of Object.values(newData.edges).map((item) =>
			Object.values(item)
		))
			elems = elems.concat(elem);

        let resultElems = elems.filter(elem => {
            if (!isValidId(elem.source) || !isValidId(elem.target)) {
                console.error(`Invalid Edge ID: ${elem}`);
                return false;
            }
            return (newData.nodes[elem.source] || elem.source === '000000000000000000000000') && newData.nodes[elem.target];
        });

        let result = {}
        for (let elem of resultElems) {
            if (!result[elem.source])
                result[elem.source] = { [elem.target]: elem }
            else
                result[elem.source][elem.target] = elem;
        }
        return result;
    })();

    newData.intents = Object.values(newData.intents ?? [])
        .filter(intent => {
            if (!isValidId(intent.id)) {
                console.error(`Invalid Intent ID: ${intent}`);
                return false;
            }
            return intent?.title;
        })
        .map(intent => ({
            ...intent,
            examples: intent?.examples?.filter(example => example?.text)
        }))
        .reduce((acc, intent) => ({
            ...acc,
            [intent.id]: intent
        }), {});

    return newData;
}



export const getValueFromObject = (object, path) => {
	if (path[0] === ".") path = path.substring(1, path.length);

	const keys = path.split(".");
	let current = object;

	for (const key of keys) {
		if (current.hasOwnProperty(key)) {
			current = current[key];
		} else {
			return undefined;
		}
	}

	return current;
};

export const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const formatPhoneNumber = (phoneNumber) => {
    let formatted;
    if(phoneNumber.length === 12)
        formatted = phoneNumber.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1($2) $3-$4');
    if(phoneNumber.length === 13)
        formatted = phoneNumber.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1($2) $3-$4');

    return formatted;
}

export const updatedSince = (savedAt) => {
	let secs = Math.floor((Date.now() - savedAt.getTime()) / 1000);
	if (secs <= 60) {
		return secs + " segundo" + (secs !== 1 ? "s" : "");
	}
	let mins = Math.floor(secs / 60);
	if (mins <= 60) {
		return mins + " minuto" + (mins !== 1 ? "s" : "");
	}

	let hours = Math.floor(mins / 60);
	if (hours <= 24) {
		return hours + " hora" + (hours !== 1 ? "s" : "");
	}

	let days = Math.floor(hours / 24);
	if (days <= 99) {
		return days + " dia" + (days !== 1 ? "s" : "");
	}

	let months = Math.floor(days / 30);
	if (months <= 12) {
		return months + (days !== 1 ? " meses" : " mês");
	}

	let years = Math.floor(months / 12);
	return years + " ano" + (years !== 1 ? "s" : "");
};

export const isValidCPF = (cpf) => {
	if (typeof cpf !== "string") return false;
	cpf = cpf.replace(/\D/g, "");
	if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

	const calculateDigit = (digits, multiplier) => {
		let sum = digits.reduce((acc, digit, i) => acc + digit * (multiplier - i), 0);
		const remainder = sum % 11;
		return remainder < 2 ? 0 : 11 - remainder;
	};

	const digits = cpf.split("").map(Number);
	const firstDigit = calculateDigit(digits.slice(0, 9), 10);
	const secondDigit = calculateDigit(digits.slice(0, 10), 11);

	return firstDigit === digits[9] && secondDigit === digits[10];
};

export function isSimilar(s1, s2, threshould = 0.2) {
	let longer = s1.substring(0, s2?.length);
	let shorter = s2;
	if (s1.length < s2.length) {
		longer = s2;
		shorter = s1;
	}
	let longerLength = longer.length;
	if (longerLength === 0) {
		return 1.0;
	}
	const n =
		(longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);

	return n > threshould;
}

export function formatDate(date) {
	return new Date(date).toLocaleDateString("pt-BR", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	});
}

function editDistance(s1, s2) {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();

	let costs = [];
	for (let i = 0; i <= s1.length; i++) {
		let lastValue = i;
		for (let j = 0; j <= s2.length; j++) {
			if (i === 0) costs[j] = j;
			else {
				if (j > 0) {
					let newValue = costs[j - 1];
					if (s1.charAt(i - 1) !== s2.charAt(j - 1))
						newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
					costs[j - 1] = lastValue;
					lastValue = newValue;
				}
			}
		}
		if (i > 0) costs[s2.length] = lastValue;
	}
	return costs[s2.length];
}

export const convertCsvToArray = (csvFile) => {
    const result = Papa.parse(csvFile, {
        header: false,
        skipEmptyLines: true,
    });

    if (result.errors.length) {
        console.error('Error parsing CSV:', result.errors);
    }

    return result.data;
};
