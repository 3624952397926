import ChatTest from "../../../components/chat-test";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    Card,
    Col,
    Row,
    theme,
    Space,
    Typography,
    Button, Menu, Empty, Modal, Form, Input, Switch, Select
} from "antd";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    BuildOutlined,
    BulbOutlined,
    CheckOutlined,
    CopyOutlined,
    EditOutlined, HistoryOutlined,
    SettingOutlined,
    ThunderboltOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {getAssistant, updateAssistant} from "../../../services/assistant";
import Loading from "../../../components/loading";
import {getDashboard} from "../../../services/dashboard";
import dayjs from "dayjs";
import {DashboardGraphs} from "../../../components/dashboard-graphs";
import MessageApiContext from "../../../context/message-api";
import Documents from "./Knowledge";
import Integrations from "./Integrations";
import Triggers from "./Triggers";
import Historic from "./Historic";
import UserContext from "../../../context/users";
import {AdminRole} from "../../../constants";
import Tasks from "./Tasks";

const defaultDate = [
    dayjs().subtract(7, 'day'),
    dayjs(),
]

export default function EditGenerative() {
    const location = useLocation();
    const {id} = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const messageApi = useContext(MessageApiContext);
    const [graphData, setGraphData] = useState({});
    const [graphLoading, setGraphLoading] = useState(true);
    const navigate = useNavigate();
    const [user] = useContext(UserContext);
    const locationSplit = location?.pathname?.split('/');
    const isTaskRoute = location.pathname.includes("/tasks");
    const colGrow = isTaskRoute ?  20 : 12;

    const loadGraph = useCallback((id) => {
        if (!id)
            return;

        const defaultFilters = {
            serviceID: id,
            startDate: defaultDate[0].toISOString(),
            endDate: defaultDate[1].toISOString()
        };

        setGraphLoading(true);
        let filters = {...defaultFilters}
        filters.startDate = defaultFilters.startDate.substring(0, 10) + 'T00:00:00Z';
        filters.endDate = defaultFilters.endDate.substring(0, 10) + "T23:59:59Z";
        filters.service = "ASSISTANT";

        getDashboard(filters).then(({data}) => {
            setGraphData(data)
        }).catch(e => {
            console.error(e);
            messageApi.error("Falha ao carregar dashboard");
        })
            .finally(() => setGraphLoading(false));
    }, [])

    const menuItems = useMemo(() => [
        {
            label: 'Geral',
            key: "general",
            icon: <SettingOutlined />,
        },
        {
            label: 'Conhecimento',
            key: "knowledge",
            icon: <BulbOutlined />,
        },
        {
            label: 'Integrações',
            key: "integrations",
            icon: <BuildOutlined />,
        },
        {
            label: 'Gatilhos',
            key: "triggers",
            icon: <ThunderboltOutlined />,
        },
        {
            label: 'Histórico',
            key: "historic",
            icon: <HistoryOutlined />,
        },
        {
            label: 'Tarefas',
            key: "tasks",
            icon: <UnorderedListOutlined />,
        },
    ], []);

    useEffect(() => {
        setLoading(true)
        getAssistant(id).then(({data}) => {
            setData({...data});
            loadGraph(data?.id);
            setLoading(false)
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao carregar informações do chatbot")
        }).finally(() => {
            setLoading(false)
        })
    }, []);

    const checkMenuAdmin = useCallback(() => {
        if(user?.role !== AdminRole)
            return menuItems.pop();
        return menuItems;
    }, [user.role, menuItems])

    useEffect(() =>{
        checkMenuAdmin();
    }, [checkMenuAdmin]);

    if (loading) {
        return <Loading title={'Carregando...'}/>;
    }

    return (
        <Row gutter={[12, 12]}>
            <Col span={4}>
                <Menu
                    style={{width: '100%', height: '100%', backgroundColor: "transparent"}}
                    mode="inline"
                    onClick={({key}) => key === 'general' ? navigate('') : navigate(key)}
                    selectedKeys={locationSplit?.length === 3 ? ['general'] : locationSplit}
                    items={menuItems}
                />
            </Col>
            <Col sm={20} xs={20} md={20} lg={colGrow} xl={colGrow} style={{padding: '0 48px'}}>
                <Routes>
                    <Route index path={'/'}
                           element={<General data={data}
                                             setData={setData}
                                             graphLoading={graphLoading}
                                             graphData={graphData}/>}/>
                    <Route path={'/knowledge'}
                           element={<Documents id={id}/>}/>
                    <Route path={'/integrations'}
                           element={<Integrations id={id} messageApi={messageApi}/>}/>
                    <Route path={'/historic/*'} element={<Historic assistantId={id}/>}/>
                    <Route path={'/triggers/*'} element={<Triggers assistantId={id}/>}/>
                    <Route path={'/tasks/*'} element={<Tasks assistantId={id}/>}/>
                    <Route path={'*'}
                           element={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                           description={'Em construção...'}/>}/>
                </Routes>
            </Col>
            {!isTaskRoute && <Col sm={24} xs={24} md={24} lg={8} xl={8}>
                <Card>
                    <div style={{height: '70vh'}}>
                        <ChatTest id={id} status={'DONE'} internalVersion={1} chatbot={id} generative/>
                    </div>
                </Card>
            </Col>}
        </Row>
    )
}

function General({graphLoading, graphData, data, setData}) {
    const [editOpen, setEditOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const messageApi = useContext(MessageApiContext);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(data);
    }, [data]);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    const updateData = useCallback((newData) => {
        setUpdating(true);
        updateAssistant(data.id, {...data, ...newData}).then(({data}) => {
            setData(data);
            messageApi.success("Assistente atualizado com sucesso");
            setEditOpen(false);
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao atualizar assistente")
        }).finally(() => setUpdating(false))
    }, [data, messageApi])

    return (
        <Space direction={'vertical'} size={'large'} style={{width: '100%'}}>
            <Modal title={'Atualizar Assistente'} open={editOpen} okText={"Salvar"} cancelText={"Cancelar"}
                   confirmLoading={updating}
                   onOk={() => form.submit()}
                   onCancel={() => {
                    setEditOpen(false)
                    form.setFieldsValue(data)}}>
                <UpdateForm form={form} onFinish={updateData}/>
            </Modal>
            <Row justify={'space-between'} style={{width: '100%'}}>
                <div>
                    <div>
                        <Typography.Text>
                            Assistant ID:
                        </Typography.Text>
                        <Typography.Text
                            code
                            copyable={{
                                icon: [<CopyOutlined style={{color: colorPrimary}} key="copy-icon"/>,
                                    <CheckOutlined style={{color: colorPrimary}} key="copied-icon"/>],
                                tooltips: ['Copiar', 'Copiado!'],
                            }}>
                            {data.id}
                        </Typography.Text>
                    </div>
                    <Row justify={'space-between'}>
                        <Col span={20}>
                            <Typography.Title level={1}>
                                {data.name}
                                <EditOutlined
                                    style={{color: colorPrimary}}
                                    onClick={() => setEditOpen(true)}/>
                            </Typography.Title>
                        </Col>
                        <Col>
                            <Button onClick={() => setEditOpen(true)} icon={<SettingOutlined/>}/>
                        </Col>
                    </Row>
                    <Typography.Paragraph
                        level={1}
                        ellipsis={{
                            rows: 2, suffix: <EditOutlined
                                style={{color: colorPrimary}}
                                onClick={() => setEditOpen(true)}/>
                        }}
                    >
                        {data.prompt}
                    </Typography.Paragraph>
                </div>
            </Row>
            <DashboardGraphs graphType={'area'} withList={false} data={graphData}
                             noBackground
                             justifyCard={'space-between'}
                             loading={graphLoading}/>
        </Space>
    )
}

const llmOptions = {
    "OPENAI": [
        {
            label: "GPT-3.5 Turbo",
            value: "gpt-3.5-turbo"
        },
        {
            label: "GPT-4 Turbo 2024-04-09",
            value: "gpt-4-turbo-2024-04-09"
        },
        {
            label: "GPT-4 Turbo",
            value: "gpt-4-turbo"
        },
        {
            label: "GPT-4o",
            value: "gpt-4o"
        },
        {
            label: "GPT-4o-Mini",
            value: "gpt-4o-mini"
        },
        {
            label: "GPT-4o-Large",
            value: "gpt-4o-large"
        }
    ],
    "GROQ": [
        {
            label: "Llama3 8B",
            value: "llama3-8b-8192"
        },
        {
            label: "Llama3 70B",
            value: "llama3-70b-8192"
        },
        {
            label: "Mixtral 8x7B",
            value: "mixtral-8x7b-32768"
        },
        {
            label: "Gemma 7B IT",
            value: "gemma-7b-it"
        }
    ],
    "NVIDIA": [
        {
            label: "Mistral Large",
            value: "mistralai/mistral-large"
        },
        {
            label: "Llama3 70B",
            value: "meta/llama3-70b-instruct"
        },
        {
            label: "Llama3 8B",
            value: "meta/llama3-8b-instruct"
        }
    ],
    "GOOGLE": [
        {
            label: "Gemini 1.5 Flash",
            value: "gemini-1.5-flash-001"
        },
        {
            label: "Gemini 1.5 Pro",
            value: "gemini-1.5-pro-001"
        },
        {
            label: "Gemini Experimental",
            value: "gemini-experimental"
        }
    ]
}

const audioInterpreterOptions = {
    "OPENAI": [
        {
            label: "Whisper V2",
            value: "whisper-1"
        },
    ]
}

function UpdateForm({form, ...props}) {
    const llmProvider = Form.useWatch('llmProvider', form);
    const audioProvider = Form.useWatch('audioProvider', form);
    const [user] = useContext(UserContext);
    const isPersonalized = Form.useWatch('isCustomPrompt', form)

    const updateLLMProvider = useCallback((value) => {
        const firstOption = llmOptions[value]?.[0]?.value;
        form.setFieldsValue({ llmModel: firstOption });
    }, []);

    return (
        <Form layout={'vertical'} form={form} {...props}>
            <Row justify={'space-between'}>
                <Col span={user.role === AdminRole ? 14 : 18}>
                    <Form.Item label={'Nome'}
                               rules={[
                                   {required: true, message: 'Nome é obrigatório'},
                                   {max: 100, message: 'Nome deve ter no máximo 100 caracteres'},
                                   {min: 3, message: 'Nome deve ter no mínimo 3 caracteres'}
                               ]}
                               name={'name'}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item label={'Ativo'}
                               name={'isActive'}
                               tooltip={'O Assistente não pode ser acessado externamente se estiver desativado.'}
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Col>
                {user.role === AdminRole && <Col span={6}>
                    <Form.Item label={'Personalizado'}
                               name={'isCustomPrompt'}
                               tooltip={'O prompt do assistente é personalizado.'}
                               valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Col>}
            </Row>
            {user.role === AdminRole && Boolean(isPersonalized) && <Form.Item label={'Prompt Personalizado'}
                                                                              rules={[
                                                                                  {
                                                                                      required: true,
                                                                                      message: 'O prompt é obrigatório.'
                                                                                  },
                                                                              ]}
                                                                              name={'customPrompt'}>
                <Input.TextArea autoSize/>
            </Form.Item>}
            {!Boolean(isPersonalized) && <Form.Item label={'Instrução'}
                                                    rules={[
                                                        {required: true, message: 'O prompt é obrigatório.'},
                                                        {
                                                            max: 2500,
                                                            message: 'O prompt deve ter menos que 2500 caracteres.'
                                                        },
                                                        {min: 10, message: 'O prompt deve ter mais que 10 caracteres.'}
                                                    ]}
                                                    name={'prompt'}>
                <Input.TextArea autoSize
                                count={{
                                    show: true,
                                    max: 2500,
                                }}
                />
            </Form.Item>}
            {user.role === AdminRole && <>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Form.Item label={'Interpretador de Audio'}
                                   name={'audioProvider'}>
                            <Select options={[
                                {
                                    label: 'OpenAI',
                                    value: 'OPENAI',
                                }
                            ]}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Modelo de Audio'}
                                   name={'audioModel'}>
                            <Select options={audioInterpreterOptions[audioProvider]}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Form.Item label={'Provedor de AI'}
                                   name={'llmProvider'}>
                            <Select
                                onChange={updateLLMProvider}
                                options={[
                                {
                                    label: 'OpenAI',
                                    value: 'OPENAI',
                                },
                                {
                                    label: 'GROQ',
                                    value: 'GROQ',
                                },
                                {
                                    label: 'NVIDIA',
                                    value: 'NVIDIA',
                                },
                                {
                                    label: 'Google',
                                    value: 'GOOGLE',
                                }
                            ]}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Modelo'}
                                   name={'llmModel'}>
                            <Select options={llmOptions[llmProvider]}/>
                        </Form.Item>
                    </Col>
                </Row>
            </>
            }
            <Form.Item label={'Restrito ao contexto'}
                       name={'restrictedByContext'}
                       tooltip={"Ao ativar as respostas do bot serão restritas aos conhecimentos dos documentos fornecidos."}
                       valuePropName="checked">
                <Switch disabled={isPersonalized}/>
            </Form.Item>
            <Form.Item label={'Limite de resposta'}
                       name={'maxTokens'}>
                <Select defaultValue={300} options={[
                    {value: 0, label: "Automático"},
                    {value: 300, label: "Curta"},
                    {value: 500, label: "Média"},
                    {value: 700, label: "Longa"},
                ]}/>
            </Form.Item>
        </Form>
    )

}
