import React, {useCallback, useContext, useEffect, useState} from "react";
import MessageApiContext from "../../../../context/message-api";
import {
    createScrapWebsite,
    createTextData,
    deleteFile,
    getFile,
    getFiles,
    updateFile
} from "../../../../services/assistant";
import {
    Avatar,
    Button,
    Card, Checkbox,
    Col,
    Empty, Form,
    Input,
    List, Modal,
    Popconfirm,
    Progress,
    Row,
    Space,
    Tag,
    theme,
    Typography,
    Upload
} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    FileTextOutlined,
    InboxOutlined, LinkOutlined, PlusOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {updatedSince} from "../../../../utils";

const {Dragger} = Upload;
const defaultUrl = process.env.REACT_APP_SERVICE;

const progressObj = {
    "READY_TO_PROCESS": 0,
    "CONVERTING_TO_PDF": 25,
    "GENERATING_VECTORS": 50,
    "SAVING_VECTORS": 75,
    "DONE": 100,
}

const progressSite = {
    "READY_TO_PROCESS": "AGUARDANDO...",
    "CONVERTING_TO_PDF": "COLETANDO INFORMAÇÕES...",
    "GENERATING_VECTORS": "TREINANDO...",
    "SAVING_VECTORS": "SALVANDO...",
    "ERROR": "ERRO",
    "DONE": "PRONTO",
    "UPLOADING": "ENVIANDO...",
}

const progressDoc = {
    "READY_TO_PROCESS": "AGUARDANDO...",
    "CONVERTING_TO_PDF": "CONVERTENDO...",
    "GENERATING_VECTORS": "TREINANDO...",
    "SAVING_VECTORS": "SALVANDO...",
    "ERROR": "ERRO",
    "DONE": "PRONTO",
    "UPLOADING": "ENVIANDO...",
}

export default function Knowledge({id}) {
    const [siteOpen, setSiteOpen] = useState();
    const [textOpen, setTextOpen] = useState();
    const [fileList, setFileList] = useState([]);

    return (<>
            <Site siteOpen={siteOpen}
                  setFileList={setFileList}
                  setSiteOpen={setSiteOpen}
                  id={id}/>

            <Text textOpen={textOpen}
                  setFileList={setFileList}
                  setTextOpen={setTextOpen}
                  id={id}/>
            <Space style={{width: '100%'}} direction={'vertical'}>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Button style={{height: 80, width: '100%', overflow: 'clip'}}
                                onClick={() => setSiteOpen(true)}
                                size={'large'} icon={<PlusOutlined/>}>Adicionar
                            site</Button>
                    </Col>
                    <Col span={12}>
                        <Button style={{height: 80, width: '100%', overflow: 'clip'}}
                                onClick={() => setTextOpen(true)}
                                size={'large'} icon={<PlusOutlined/>}>Adicionar
                            Texto</Button>
                    </Col>
                </Row>
                <Documents fileList={fileList} setFileList={setFileList} id={id}/>
            </Space>
        </>
    )
}

function Site({id, setSiteOpen, siteOpen, setFileList}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const onFinish = useCallback(async (values) => {
        await form.validateFields()

        setLoading(true)
        createScrapWebsite({
            assistantID: id,
            data: values
        }).then(({data}) => {
            setSiteOpen(false);
            setFileList(old => [data, ...old])
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao requisitar treino.")
        }).finally(() => {
            setLoading(false);
        })

    }, [form]);

    return <Modal open={siteOpen}
                  okText={"Treinar"}
                  confirmLoading={loading}
                  cancelText={"Cancelar"}
                  onOk={form.submit}
                  title={"Informações do site"}
                  onCancel={() => {
                      setSiteOpen(false)
                      form.resetFields()
                  }}>
        <Space direction={'vertical'} style={{width: '100%'}}>
        <Typography.Text>
                O assistente será treinado com todas as informações contidas no site e nos links relacionados.
            </Typography.Text>

            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Form.Item label={'Titulo'}
                           name={'title'}
                           rules={[
                               {
                                   required: true,
                                   message: "O titulo é obrigatório"
                               },
                               {
                                   min: 3,
                                   max: 48,
                                   message: "O titulo deve ter entre 3 e 48 caracteres"
                               }
                           ]}
                >
                    <Input placeholder={'Site do google'}/>
                </Form.Item>
                <Form.Item label={'URL'}
                           name={'url'}
                           rules={[
                               {
                                   required: true,
                                   message: "O URL do site é obrigatório"
                               },
                               {
                                   pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                                   message: 'URL inválido',
                               }
                           ]}
                >
                    <Input placeholder={'https://google.com'}/>
                </Form.Item>
                <Form.Item label={'Recursivo'}
                           help={"Ao ativar essa opção todos os links relacionados ao domínio serão buscados dentro da página informada."}
                           name={'recursive'}
                           valuePropName="checked">
                    <Checkbox>Ativar busca recursiva</Checkbox>
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button htmlType={'submit'}></Button>
                </Form.Item>
            </Form>
        </Space>
    </Modal>
}

function Text({id, setTextOpen, textOpen, setFileList}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const messageApi = useContext(MessageApiContext);

    const onFinish = useCallback(async (values) => {
        await form.validateFields()

        setLoading(true)
        createTextData({
            assistantID: id,
            data: values
        }).then(({data}) => {
            setTextOpen(false);
            setFileList(old => [data, ...old])
        }).catch((e) => {
            console.error(e)
            messageApi.error("Falha ao requisitar treino.")
        }).finally(() => {
            setLoading(false);
        })

    }, [form]);

    return <Modal open={textOpen}
                  okText={"Treinar"}
                  confirmLoading={loading}
                  cancelText={"Cancelar"}
                  onOk={form.submit}
                  title={"Informações de treinamento"}
                  onCancel={() => {
                      setTextOpen(false)
                      form.resetFields()
                  }}>
        <Space direction={'vertical'} style={{width: '100%'}}>
            <Typography.Text>
                O assistente será treinado com todas as informações do texto.
            </Typography.Text>

            <Form layout={'vertical'} form={form} onFinish={onFinish}>
                <Form.Item label={'Titulo'}
                           name={'title'}
                           rules={[
                               {
                                   required: true,
                                   message: "O titulo é obrigatório"
                               },
                               {
                                   min: 3,
                                   max: 48,
                                   message: "O titulo deve ter entre 3 e 48 caracteres"
                               }
                           ]}
                >
                    <Input placeholder={'Título das informações'}/>
                </Form.Item>
                <Form.Item label={'Texto'}
                           name={'text'}
                           rules={[
                               {
                                   required: true,
                                   message: "O URL do site é obrigatório"
                               },
                               {
                                   min: 10,
                                   message: 'O texto deve ter mais de 10 caracteres',
                               }
                           ]}
                >
                    <Input.TextArea autoSize placeholder={'Adicione um texto qualquer para treinar a IA'}/>
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button htmlType={'submit'}></Button>
                </Form.Item>
            </Form>
        </Space>
    </Modal>
}

function Documents({id, fileList, setFileList}) {
    const [loading, setLoading] = useState(true);
    const messageApi = useContext(MessageApiContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(10);

    const update = useCallback(() => {
        setLoading(true);
        getFiles({assistantID: id, page, perPage}).then(({data, total}) => {
            setTotal(total);
            setFileList(data)
        })
            .catch(console.error)
            .finally(() => setLoading(false))
    }, [id, page, perPage])

    useEffect(update, [id, page, perPage]);

    const handleChange = useCallback((info) => {
        if (info?.event) {
            setFileList(old => old.map(item => {
                if (item.id === info.file.uid)
                    return {...item, percent: info.event.percent, target: info.event.target};

                return {...item}
            }));
            return
        }

        if (info?.fileList?.length < 1)
            return;

        setFileList(old => [{
            id: info.file.uid,
            title: info.file.name,
            status: 'UPLOADING',
            percent: 0,
            assistantID: id,
        }, ...old]);
    }, [fileList]);

    const handleSuccess = ({data}, second) => {
        setFileList(old => [...old.map(item => {
            if (item.id === second.uid)
                return data;

            return {...item}
        })])
    };

    return (<Space style={{width: '100%'}} direction={'vertical'}>
        <Dragger
            onChange={handleChange}
            onSuccess={handleSuccess}
            showUploadList={false}
            disabled={loading}
            action={`${defaultUrl}/assistant/${id}/file/`}
            name={'doc'}
            headers={{
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }}
            accept={'.PDF,.MP3,.MP4,.MPEG'}
            multiple>
            <p className="ant-upload-drag-icon">
                <InboxOutlined/>
            </p>
            <p className="ant-upload-text">Clique ou arraste os arquivos para a área</p>
            <p className="ant-upload-hint">
                Suporta o envio de um ou mais arquivos.<br/>
                Arquivos suportados: PDF, MP3, MP4 e MPEG...
            </p>
        </Dragger>
        <List
            loading={loading}
            size="small"
            dataSource={fileList}
            locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description={"Nenhum arquivo, clique acima para subir"}/>
            }}
            renderItem={(item, i) => <Document onDelete={() => setFileList(old => old.filter((_, i2) => i !== i2))}
                                               messageApi={messageApi} {...item}/>}
            pagination={{
                position: "bottom",
                align: "end",
                onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                },
                total,
                pageSize: perPage,
                current: page
            }}
        />
    </Space>);
}

function Document({
                      title: initialTitle,
                      status: initialStatus,
                      link: initialLink,
                      size,
                      onDelete,
                      type,
                      assistantID,
                      createdAt,
                      id,
                      messageApi,
                      target
                  }) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [status, setStatus] = useState(initialStatus);
    const [title, setTitle] = useState(initialTitle);
    const [link, setLink] = useState(initialLink);
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const [percent, setPercent] = useState(0);
    useEffect(() => {
        console.log(type)
    }, [type])

    useEffect(() => {
        form.setFieldsValue({link, title})
    }, [link, title]);

    useEffect(() => {
        setTitle(initialTitle);
        setLink(initialLink);
        if (!target)
            return;

        target.addEventListener('progress', (e) => {
            setPercent(e.loaded / e.total * 100);
        });
    }, [target, initialTitle, initialLink])

    useEffect(() => {
        setStatus(initialStatus);
    }, [initialStatus]);

    const finishSave = (values) => {
        setSaving(true);
        updateFile({
            assistantID: assistantID,
            fileId: id,
            data: values
        }).then((res) => {
            setLink(res.data.link)
            setTitle(res.data.title)
        }).catch((e) => {
            messageApi.error("Falha ao atualizar documento");
            console.error(e)
        }).finally(() => {
            setIsEditing(false);
            setSaving(false);
        });
    };

    useEffect(() => {
        let interval;
        if (status !== 'DONE' && status !== 'ERROR' && status !== 'UPLOADING') {
            updateStatus();
            interval = setInterval(updateStatus, 5000);
        }

        return () => clearInterval(interval)
    }, [status])

    const delDoc = () => {
        setLoading(true)
        deleteFile({assistantID: assistantID, fileId: id}).then(() => {
            onDelete();
            messageApi.success("Arquivo deletado com sucesso");
        }).catch(e => {
            messageApi.error("Falha ao deletar documento");
            console.error(e)
        }).finally(() => setLoading(false));
    }

    const updateStatus = useCallback(() => {
        getFile({assistantID: assistantID, fileId: id}).then(({data: {status}}) => {
            setStatus(status)
        }).catch(console.error)
    }, [assistantID, id]);

    const {
        token: {colorPrimary},
    } = theme.useToken();

    return (<List.Item actions={[
        <Button icon={<EditFilled/>}
                disabled={loading || status !== 'DONE'}
                onClick={() => setIsEditing(true)}/>,
        <Popconfirm title={'Tem certeza?'}
                    description={"Esta ação é irreversível."}
                    disabled={loading || (status !== 'DONE' && status !== 'ERROR')}
                    cancelText={'Cancelar'}
                    onConfirm={delDoc}>
            <Button icon={<DeleteOutlined/>}
                    danger
                    disabled={loading || (status !== 'DONE' && status !== 'ERROR')}
                    loading={loading}/>
        </Popconfirm>
    ]}>
        <Modal open={isEditing}
               okText={'Salvar'}
               onCancel={() => setIsEditing(false)}
               title={'Editar documento'}
               onOk={form.submit}
               confirmLoading={saving}
               cancelText={'Cancelar'}>
            <DocumentEdit form={form}
                          onFinish={finishSave}/>
        </Modal>
        <List.Item.Meta
            title={title}
            avatar={<DocumentAvatar type={type} status={status}/>}
            description={<Space direction={'vertical'} style={{width: '100%'}}>
                {status !== 'DONE' ?
                    <Tag color={status === 'DONE' ? 'success' : status === 'ERROR' ? 'error' : 'processing'}
                         icon={status === 'DONE' ? <CheckOutlined/> : status === 'ERROR' ?
                             <CloseOutlined/> :
                             <SyncOutlined spin/>}>{(type?.type !== 'URL') ? progressDoc[status] : progressSite[status]}</Tag> :
                    <Space>
                        Criado {updatedSince(new Date(createdAt))} atrás
                        {link?.length > 0 && <a href={link} target="_blank"><Button
                            type={'link'}
                            icon={<LinkOutlined/>}
                        >Acessar Link</Button></a>}
                    </Space>
                }
                {status === "UPLOADING" &&
                    <Progress strokeColor={colorPrimary}
                              percent={percent.toFixed(2)}
                              size="small"
                              status="active"/>
                }
            </Space>}
        />
        {/*<Row gutter={[8,8]} justify={'space-around'} align={'middle'}>*/}
        {/*    <Col>*/}
        {/*        <Progress size={75} percent={status === "UPLOADING" ? percent : 100}*/}
        {/*                  format={() => {*/}
        {/*                      if (status === 'DONE')*/}
        {/*                          return <CheckOutlined/>;*/}

        {/*                      if (status === 'ERROR')*/}
        {/*                          return <CloseOutlined style={{color: colorError}}/>;*/}

        {/*                      if (status === "UPLOADING")*/}
        {/*                          return percent?.toFixed(0) + '%';*/}

        {/*                      return progressObj[status] + '%';*/}

        {/*                  }}*/}
        {/*                  strokeColor={status === 'ERROR' && colorError}*/}
        {/*                  trailColor={status === 'ERROR' && colorError}*/}
        {/*                  success={{percent: progressObj[status]}} type="circle"/>*/}
        {/*    </Col>*/}
        {/*    <Col span={14}>*/}
        {/*        <Space direction={'vertical'} style={{width: '100%'}}>*/}
        {/*            {isEditing ? (*/}
        {/*                <Input*/}
        {/*                    defaultValue={title}*/}
        {/*                    style={{minWidth: 0, margin: 0, maxWidth: '80%'}}*/}
        {/*                    onChange={(e) => {*/}
        {/*                        setTitleToUpdate(e.target.value)*/}
        {/*                    }}*/}
        {/*                />*/}
        {/*            ) : (*/}
        {/*                <Typography.Title style={{margin: 0, minWidth: 0, padding: 4, maxWidth: '99.997%'}} level={5}*/}
        {/*                                  ellipsis={{rows: 1, suffix: ''}}>*/}
        {/*                    {title}*/}
        {/*                </Typography.Title>*/}
        {/*            )}*/}
        {/*            {isEditing ? (*/}
        {/*                <Input*/}
        {/*                    defaultValue={link}*/}
        {/*                    size={"small"}*/}
        {/*                    placeholder={"Insira um link do seu arquivo..."}*/}
        {/*                    onChange={(e) => setLinkToUpdate(e.target.value)}*/}
        {/*                    style={{minWidth: 0, margin: 0, maxWidth: '80%'}}*/}
        {/*                />*/}
        {/*            ) : link ? (*/}
        {/*                <Typography.Link*/}
        {/*                    style={{marginLeft: 4, minWidth: 0, padding: 1, maxWidth: "60%"}}*/}
        {/*                    ellipsis={true}*/}
        {/*                    href={link}*/}
        {/*                    title={link}*/}
        {/*                    target="_blank"*/}
        {/*                >*/}
        {/*                    {link}*/}
        {/*                </Typography.Link>*/}
        {/*            ) : null}*/}
        {/*            <Space>*/}
        {/*                <Tag style={{marginLeft: 4}}*/}
        {/*                     color={status === 'DONE' ? 'success' : status === 'ERROR' ? 'error' : 'processing'}*/}
        {/*                     icon={status === 'DONE' ? <CheckOutlined/> : status === 'ERROR' ? <CloseOutlined/> :*/}
        {/*                         <SyncOutlined spin/>}>{progressName[status]}</Tag>*/}
        {/*                {size && <Typography.Text>- {(size / 1000000).toFixed(2)} MB</Typography.Text>}*/}
        {/*            </Space>*/}
        {/*        </Space>*/}
        {/*    </Col>*/}
        {/*    <Col span={4}>*/}
        {/*        <Row justify={'end'}>*/}
        {/*            <Space align={'end'} wrap>*/}
        {/*                <Button icon={isEditing ? <CheckOutlined/> : <EditFilled/>}*/}
        {/*                        type={isEditing ? "primary" : "default"}*/}
        {/*                        onClick={() => {*/}
        {/*                            if (isEditing) {*/}
        {/*                                updateDocumentReq()*/}
        {/*                            } else {*/}
        {/*                                setIsEditing(true)*/}
        {/*                            }*/}
        {/*                        }*/}
        {/*                        } disabled={status !== 'DONE'}*/}
        {/*                        loading={isLoadingEdit || loading}/>*/}
        {/*                {!isEditing ? (*/}
        {/*                        <Popconfirm title={'Tem certeza?'} description={"Esta ação é irreversível."}*/}
        {/*                                    cancelText={'Cancelar'}*/}
        {/*                                    onConfirm={delDoc}>*/}
        {/*                            <Button icon={<DeleteOutlined/>} loading={loading}/>*/}
        {/*                        </Popconfirm>) :*/}
        {/*                    (<Button icon={<CloseOutlined/>} disabled={isLoadingEdit} danger*/}
        {/*                             onClick={() => {*/}
        {/*                                 setLinkToUpdate(link)*/}
        {/*                                 setTitleToUpdate(title)*/}
        {/*                                 setIsEditing(false)*/}
        {/*                             }}/>)}*/}
        {/*            </Space>*/}
        {/*        </Row>*/}
        {/*    </Col>*/}
        {/*</Row>*/}
    </List.Item>)
}

function DocumentEdit({form, onFinish}) {
    return (
        <Form layout={'vertical'}
              onFinish={onFinish}
              form={form}>
            <Form.Item name={'title'}
                       rules={[{
                           required: true,
                           min: 3,
                           max: 48,
                           message: "O título é obrigatório"
                       }]}
                       label={'Título'}>
                <Input placeholder={'Titulo do documento'}/>
            </Form.Item>
            <Form.Item name={'link'}
                       rules={[{
                           pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                           message: 'URL inválido',
                       }]}
                       label={'Link'}>
                <Input placeholder={'Link do documento'}/>
            </Form.Item>

            <Form.Item style={{display: 'none'}}>
                <Button htmlType={'submit'}/>
            </Form.Item>
        </Form>
    )
}

function DocumentAvatar({type, status}) {
    let icon = <FileTextOutlined/>;
    const {
        token: {colorBgLayout, colorPrimary, colorPrimaryBg, colorPrimaryActive, colorErrorBg, colorErrorActive},
    } = theme.useToken();

    if (status === 'ERROR')
        return <Avatar style={{
            backgroundColor: colorErrorBg,
            color: colorErrorActive
        }} size={48} icon={<CloseOutlined/>}/>;

    if (status !== 'DONE')
        return <Progress
            type="circle"
            steps={{count: 4, gap: 12}}
            percent={progressObj[status]}
            size={48}
            strokeColor={colorPrimary}
            trailColor={colorBgLayout}
            strokeWidth={8}
        />;

    if (type?.type === 'URL')
        icon = <LinkOutlined/>;

    return <Avatar style={{
        backgroundColor: colorPrimaryBg,
        color: colorPrimaryActive
    }} size={48} icon={icon}/>
}
