import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link, Route, Routes} from "react-router-dom";
import {Button, Card, Col, Divider, Empty, Form, Input, InputNumber, List, message, Modal, Row, Typography} from "antd";
import {
    getGenerativeSessionHistory,
    getGenerativeSessions,
    insightGenerativeLogs
} from "../../../../services/generative-message-logs";
import {EyeOutlined, MessageOutlined, SendOutlined} from "@ant-design/icons";
import {formatPhoneNumber, updatedSince} from "../../../../utils";
import MessageApiContext from "../../../../context/message-api";
import View from "./View";
import messageApi from "../../../../context/message-api";
import UserContext from "../../../../context/users";
import {AdminRole} from "../../../../constants";

export default function Historic({assistantId}) {
    return (<div>
        <Routes>
            <Route path={'/'} element={<HistoricList assistantId={assistantId}/>}/>
            <Route path={'/:historic_id'} element={<View assistantId={assistantId}/>}/>
        </Routes>
    </div>)
}

function HistoricList({assistantId}) {
    const [historic, setHistoric] = useState([]);
    const [user] = useContext(UserContext);
    const messageApi = useContext(MessageApiContext);
    const [loading, setLoading] = useState(true);
    const [insightOpen, setInsightOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [total, setTotal] = useState(0)


    const getSessions = useCallback(() => {
        setLoading(true);

        getGenerativeSessions({
            assistantID: assistantId,
            page,
            perPage,
        })
            .then(({data, total}) => {
                setHistoric(data);
                setTotal(total);

                if (data.length === 0) return;

                return Promise.all(
                    data.map((item) =>
                        getGenerativeSessionHistory({sessionID: item.sessionId})
                            .then(({data: sessionData}) => {
                                const clientPhone = sessionData.find(
                                    (session) => session.clientData && session.clientData.phone
                                );

                                const name = clientPhone ? formatPhoneNumber(clientPhone.clientData.phone) : item.sessionId;

                                return {
                                    ...item,
                                    name: name,
                                };
                            })
                            .catch((err) => {
                                console.error(err);
                                return item;
                            })
                    )
                );
            })
            .then((updatedHistoric) => {
                if (updatedHistoric) setHistoric(updatedHistoric);
            })
            .catch((err) => {
                messageApi.error("Erro ao buscar histórico");
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [assistantId, messageApi, page, perPage]);

    useEffect(() => {
        getSessions();
    }, [getSessions]);

    return (<>
        <Insight open={insightOpen} messageApi={messageApi} setOpen={setInsightOpen} assistantID={assistantId}/>
        <List
            loading={loading}
            pagination={{
                position: "bottom", align: "end", onChange: (page, perPage) => {
                    setPage(page);
                    setPerPage(perPage);
                }, total, pageSize: perPage, current: page,
            }}
            locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nenhum histórico encontrado"}/>
            }}
            dataSource={historic}
            header={
                <Row justify={'space-between'}>
                    <Col>
                        <Typography.Title level={3}>Histórico</Typography.Title>
                    </Col>
                    {user.role === AdminRole && <Col>
                        <Button onClick={() => setInsightOpen(true)} icon={<MessageOutlined/>}/>
                    </Col>}
                </Row>
            }
            renderItem={(item) => (<List.Item
                actions={[<Link to={`${item?.sessionId}`}>
                    <Button type={'text'} icon={<EyeOutlined/>}/>
                </Link>]}
            >
                <List.Item.Meta
                    style={{cursor: 'pointer'}}
                    title={<Link to={`${item?.sessionId}`}>
                        {item?.name}
                    </Link>}
                    description={updatedSince(new Date(item?.createdAt)) + ' atrás | ' + item?.conversations + ' Mensage' + (item?.conversations > 1 ? 'ns' : 'm')}
                />
            </List.Item>)}
        />
    </>)
}

const Insight = ({open, setOpen, assistantID, messageApi}) => {
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);

    const onFinish = useCallback((values) => {
        setLoading(true);
        insightGenerativeLogs({
            ...values,
            assistantID: Number(assistantID)
        }).then(({data}) => {
            setResponse(data?.insight);
        }).catch(e => {
            messageApi.error("Falha ao obter insight");
            console.error(e);
        }).finally(() => setLoading(false))
    }, [assistantID])

    return (
        <Modal
            title={'Peça um insign da IA'}
            cancelText={"Fechar"}
            onCancel={() => setOpen(false)}
            footer={<Button onClick={() => setOpen(false)}>Fechar</Button>}
            open={open}>
            <br/>
            <Form layout={'vertical'} onFinish={onFinish}>
                <Form.Item name={'prompt'} label={'Mensagem'}
                           rules={[
                               {
                                   required: true,
                                   min: 10,
                                   message: "Um prompt válido tem no mínimo 10 caracteres"
                               }
                           ]}
                >
                    <Input.TextArea
                        autoSize
                        placeholder={'Existe algum problema?'}
                    />
                </Form.Item>
                <Form.Item name={'sessionRecords'}
                           label={'Quantidade de conversas'}
                           rules={[
                               {
                                   required: true,
                                   message: "A quantidade deve estar entre 10 e 300"
                               }
                           ]}
                >
                    <InputNumber min={10} max={300}/>
                </Form.Item>
                <br/>
                <Form.Item>
                    <Button
                        disabled={loading}
                        loading={loading}
                        icon={<SendOutlined/>} htmlType={'submit'}
                        type={'primary'}>Enviar</Button>
                </Form.Item>
            </Form>
            {response && <>
                <Divider/>
                <Card style={{whiteSpace: 'pre-line'}} size={'small'}>
                    {response}
                </Card>
            </>}
        </Modal>
    )
}
